
import { Options, Vue, prop } from "vue-class-component";
import { Pagination } from "@/domain/entities/Pagination";

class Props {
  paginationWidth = prop<string>({
    default: "w-full",
    type: String
  });
  renderedData = prop<number>({
    default: 0,
    type: Number
  });
  pagination = prop<Pagination>({
    default: () => {
      return new Pagination(1, 10, 0);
    },
    type: Object
  });
  isShowCaption = prop<boolean>({
    default: true,
    type: Boolean
  });
}
@Options({
  emits: ["set-page"]
})
export default class PaginationV3 extends Vue.with(Props) {
  get firstNumberOfPage() {
    if (this.pagination && this.renderedData) {
      return 1 + this.pagination.limit * (this.pagination.page - 1);
    }
    return 0;
  }
  get lastNumberOfPage() {
    if (this.pagination && this.renderedData) {
      return (
        this.renderedData + this.pagination.limit * (this.pagination.page - 1)
      );
    }
    return 0;
  }
  get isNextPageAvailable() {
    if (!this.pagination) {
      return false;
    }
    const totalOffsetData = this.pagination.totalData || 0;
    return (
      this.availablePage.length > 1 && totalOffsetData > this.pagination.limit
    );
  }
  batchPagination = 5;
  get availablePage() {
    const pages: Array<any> = [];
    const totalOffsetData = this.pagination.totalData || 0;
    const middlePagination = (this.batchPagination / 2) >> 0;
    for (let i = 0; i < this.batchPagination; i++) {
      let page = this.pagination.page + i;
      if (this.pagination.page === 2) {
        page = page - 1;
      }
      if (this.pagination.page > middlePagination) {
        page = (page - middlePagination) >> 0;
      }
      const expectedTotalDataOnPage =
        totalOffsetData - (page - this.pagination.page) * this.pagination.limit;
      if (expectedTotalDataOnPage > 0) {
        pages.push(page);
      }
    }
    const indexOfPage = pages.findIndex(page => page === this.pagination?.page);
    if (
      indexOfPage >= middlePagination &&
      pages.length < this.batchPagination
    ) {
      for (let j = 0; j <= this.batchPagination - pages.length; j++) {
        if (pages[0] - 1 > 0) {
          pages.unshift(pages[0] - 1);
        }
      }
    }
    return pages;
  }

  setPage(value: number) {
    this.$emit("set-page", value);
  }
  nextPage() {
    if (this.pagination) {
      this.$emit("set-page", this.pagination.page + 1);
    }
  }
  previousPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.$emit("set-page", this.pagination.page - 1);
  }
}
