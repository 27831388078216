
import { PropType } from "vue";
import { Options, Vue, prop } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import Checkbox from "primevue/checkbox";
import debounce from "lodash/debounce";
import {
  downloadFile,
  replaceWhiteSpaces,
} from "@/app/infrastructures/misc/Utils";
import Select from "@/app/ui/components/custom-dropdown/index.vue";
import PaginationV3 from "@/app/ui/components/pagination-v3/index.vue";
import { Pagination } from "@/domain/entities/Pagination";
import InputText from "primevue/inputtext";

class Props {
  isShowTryAgainBtn = prop<boolean>({
    default: false,
    type: Boolean,
  });
  columns = prop<Array<any>>({
    default: [],
    type: Array,
  });
  data = prop<Array<any>>({
    default: [],
    type: Array,
  });
  onClick = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function,
  });
  isDetailAble = prop<boolean>({
    default: false,
    type: Boolean,
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean,
  });
  borderTop = prop<boolean>({
    default: true,
    type: Boolean,
  });
  borderBottom = prop<boolean>({
    default: true,
    type: Boolean,
  });
  autoHeight = prop<boolean>({
    default: false,
    type: Boolean,
  });
  pagination = prop<Pagination>({
    default: () => {
      return new Pagination(1, 10, 0);
    },
    type: Object,
  });
  isShowCaptionPagination = prop<boolean>({
    default: true,
    type: Boolean,
  });
  freezeColumn = prop<number>({
    default: 1,
    type: Number,
  });
  totalPercentFreeze = prop<number>({
    default: 12,
    type: Number,
  });
  totalColumnFreeze = prop<number>({
    default: 0,
    type: Number,
  });
  isLeftFreeze = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isRightFreeze = prop<boolean>({
    default: true,
    type: Boolean,
  });
  groupAction = prop<boolean>({
    default: false,
    type: Boolean,
  });
  groupOptions = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function,
  });
  deleteAction = prop<boolean>({
    type: Boolean,
    default: false,
  });
  customDeleteAction = prop<string>({
    type: String,
    default: "",
  });
  deleteTooltip = prop<string>({
    type: String,
    default: "Hapus",
  });
  bgHeader = prop<string>({
    type: String,
    default: "",
  });
  iconNameHeader = prop<string>({
    type: String,
    default: "info",
  });
  borderRowColor = prop<string>({
    type: String,
    default: "",
  });
  headerTextColor = prop<string>({
    type: String,
    default: "",
  });
  // button action
  iconName = prop<string>({
    default: "",
    type: String,
  });
  iconColor = prop<string>({
    default: "gray-lp-600",
    type: String,
  });
  zIndexHeader = prop<string>({
    default: "z-10",
    type: String,
  });
  customButtonAction = prop<any>({
    required: false,
  });
  dynamicCustomButtonAction = prop<any>({
    required: false,
  });
  hidePagination = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isShowPagination = prop<boolean>({
    default: true,
    type: Boolean,
  });
  customClassPagination = prop<string>({
    type: String,
    default: "absolute w-full bottom-0",
  });
  isHardPagination = prop<boolean>({
    type: Boolean,
    default: false,
  });
  isSpaceBottom = prop<boolean>({
    type: Boolean,
    default: false,
  });
  errorCause = prop<string>({
    default: "",
    type: String,
  });
  showRightShadow = prop<boolean>({
    default: false,
    type: Boolean,
  });
  onTryAgain = prop<Function>({
    type: Function,
    default: Function as PropType<() => void>,
  });
  noResizeTableFreezeIndex = prop<string>({
    type: String,
    default: "",
  });
  shadowYStart = prop<string>({
    type: String,
    default: "142",
  });
  isCheckBox = prop<boolean>({
    type: Boolean,
    default: false,
  });
  isDownloadCheckBox = prop<boolean>({
    type: Boolean,
    default: true,
  });
  filterCheckBox = prop<Function>({
    type: Function,
    default: () => true,
  });
  checkedData = prop<Array<any>>({
    type: Array,
    default: [],
  });
  readyToProcess = prop<Array<any>>({
    type: Array,
    default: [],
  });
  readyToDelete = prop<Array<any>>({
    type: Array,
    default: [],
  });
  paginationWidth = prop<string>({
    default: "w-full",
    type: String,
  });
  // pin scroll bottom
  isPinnedScrollBottom = prop<boolean>({
    default: true,
    type: Boolean,
  });
  pinnedSubstractHeight = prop<string>({
    default: "225px",
    type: String,
  });
  isNoPaddingBottom = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isQuickSearch = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isSorting = prop<boolean>({
    default: false,
    type: Boolean,
  });
  paginationStyle = prop<string>({
    default: "v1",
    type: String,
  });
  isStickyHeader = prop<boolean>({
    default: true,
    type: Boolean,
  });
  infiniteScroll = prop<boolean>({
    default: false,
    type: Boolean,
  });
  firstRowHeader = prop<Array<any>>({
    default: [],
    type: Array,
  });
  secondRowHeader = prop<Array<any>>({
    default: [],
    type: Array,
  });
  isEmpty = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isFilter = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isSpaceTop = prop<boolean>({
    default: true,
    type: Boolean,
  });
  ableToHoverPerRow = prop<boolean>({
    default: false,
    type: Boolean,
  });
  hoverTemplate = prop<Function>({
    type: Function,
    default: () => true,
  });
  isTabList = prop<boolean>({
    default: false,
    type: Boolean,
  });
  emptyHeaderMessage = prop<string>({
    default: "",
    type: String,
  });
  emptyMessage = prop<string>({
    default: "Oops.. Belum ada data yang dapat ditampilkan",
    type: String,
  });
  isHideHeadCheckbox = prop<boolean>({
    default: false,
    type: Boolean,
  });
  customContentHeadCheckbox = prop<boolean>({
    default: false,
    type: Boolean,
  });
  customModelCheckbox = prop<boolean>({
    default: false,
    type: Boolean,
  });
  highlightData = prop<Array<any>>({
    default: [],
    type: Array,
  });
  isThereIsBottonWidget = prop<boolean>({
    default: false,
    type: Boolean,
  });
  iconEmpty = prop<string>({
    default: "noData_empty",
    type: String,
  });
  isGroupRow = prop<boolean>({
    default: false,
    required: false,
    type: Boolean,
  });
  keyRowColor = prop<string>({
    default: "",
    type: String,
  });
  rowColor = prop<string>({
    default: "",
    type: String,
  });
  isBorderOnHeadBgWhite = prop<boolean>({
    default: false,
    type: Boolean,
  });
}
@Options({
  inheritAttrs: false,
  components: {
    Skeleton,
    Tooltip,
    OverlayPanel,
    Checkbox,
    Select,
    PaginationV3,
    InputText,
  },
  emits: [
    "request",
    "delete-item",
    "clearCheckbox",
    "downloadCheckbox",
    "update:checkedData",
    "update:readyToProcess",
    "update:readyToDelete",
  ],
})
export default class Table extends Vue.with(Props) {
  mounted() {
    this.setResponsiveTable();
    this.initInfiniteScroll();
  }

  //pagination logic
  requestNewData() {
    this.$emit("request");
    this.isSelectAll = false;
    this.selectedCheckbox = [];
  }
  nextPage() {
    if (this.isHardPagination) {
      if (this.pagination.page === this.availablePage) {
        return;
      }
    }
    this.pagination.page++;
    this.$nextTick(() => {
      this.scrollToTop();
    });
    this.requestNewData();
  }

  prevPage() {
    if (this.pagination.page === 1) {
      return;
    }
    this.pagination.page--;
    this.$nextTick(() => {
      this.scrollToTop();
    });
    this.requestNewData();
  }

  toPage(value: number) {
    this.pagination.page = Number(value);
    this.$nextTick(() => {
      this.scrollToTop();
    });
    this.requestNewData();
  }
  toPageV3(value: number) {
    if (value === this.pagination.page) {
      return;
    }
    this.pagination.page = value;
    this.$nextTick(() => {
      this.scrollToTop();
    });
    this.requestNewData();
  }
  toDirectPage(value: number) {
    this.pagination.page = value;
    this.$nextTick(() => {
      this.scrollToTop();
    });
  }
  scrollToTop() {
    const el = document.getElementById("table-parent");
    if (!el) {
      return;
    }
    el.scroll({
      top: 0,
    });
  }

  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op.toggle(event);
  }
  columnKey = "";
  toggleMultiple(event: any, index: number, columnKey: string) {
    const refs: any = this.$refs;
    refs[`op${index}`]?.toggle(event);
    this.columnKey = columnKey;
  }
  toggleTooltip(event: any) {
    const refs: any = this.$refs;
    refs.deleteOp.toggle(event);
  }
  toggleTooltipButtonColumn(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`opBtnColumn${index}`].toggle(event);
  }
  toolTipStatus: Array<any> = [];
  toggleInfoPanel(event: any, column: any, index: number) {
    const refs: any = this.$refs;
    this.toolTipStatus[column.name] =
      this.toolTipStatus[column.name] === index ? -1 : index;
    refs[`op${column.name}${index}`]?.toggle(event);
  }
  toolTipHeaderStatus = "";
  toggleTooltipHeader(event: any, columnName: string) {
    const refs: any = this.$refs;
    this.toolTipHeaderStatus =
      this.toolTipHeaderStatus === columnName ? "" : columnName;
    refs[`opHeader${columnName}`].toggle(event);
  }

  getSizeTable(): number {
    const elementFooter = document.querySelector("#table-key");
    return (elementFooter && elementFooter.getBoundingClientRect().width) || 0;
  }

  initialTableSize = 0;
  initialIsOverflow = false;
  isWFull = false;

  setResponsiveTable() {
    if (this.isRightFreeze || this.isLeftFreeze) {
      setInterval(() => {
        const tableParent = document.querySelector("#table-parent");
        if (this.initialTableSize === 0) {
          this.initialTableSize = (tableParent && tableParent.scrollWidth) || 0;
        }
        this.isWFull =
          (tableParent && this.initialTableSize <= tableParent.clientWidth) ||
          false;
      }, 100);
    }
  }

  get getWindowScreen(): number {
    return window.screen.width;
  }

  styleCustomLeftFreeze(index: number) {
    const width =
      this.isLeftFreeze &&
      index < this.totalColumnFreeze &&
      `width: ${this.totalPercentFreeze}%;`;
    const shadow =
      index === this.totalColumnFreeze - 1 &&
      `box-shadow: 3px 0 5px -2px #e0e0e0;`;
    const margin =
      this.totalColumnFreeze !== 1 &&
      `margin-left: ${this.totalPercentFreeze}%;`;

    return `${width} ${shadow} ${margin}`;
  }

  styleCustomRightFreeze(index: number, data: Array<any>) {
    const shadow =
      index === data.length - this.totalColumnFreeze &&
      `box-shadow: -3px 0 5px -2px #e0e0e0; margin-left: 8px;`;

    let offsetWidth = 0;
    let indexColumnId = "";
    let indexColumnElement = null;
    for (let i = index + 1; i < data.length; i++) {
      indexColumnId = replaceWhiteSpaces(`${data[i].name}-${i}`);
      indexColumnElement = document.getElementById(indexColumnId);
      if (indexColumnElement) {
        offsetWidth += indexColumnElement.offsetWidth;
      }
    }
    const stickyPosition = `right: ${offsetWidth}px;`;

    return `${stickyPosition} ${shadow}}`;
  }

  // checkbox
  isSelectAll = false;
  selectedCheckbox: any[] = [];
  get emitSelectedCheckbox() {
    const selected = this.selectedCheckbox;
    this.$emit("update:checkedData", selected);
    return "";
  }

  onUpdateReadyToCargo(item: any) {
    if (item.isAbleRTC) {
      this.readyToProcess.push(item);
    } else {
      const indexElm = this.readyToProcess.findIndex(
        (key: any) => item.bagId === key.bagId
      );
      this.readyToProcess.splice(indexElm, 1);
    }
    this.$emit("update:readyToProcess", this.readyToProcess);
  }

  onUpdateReadyToDelete(item: any) {
    if (!item.isAlreadyRTC) {
      this.readyToDelete.push(item);
    } else {
      const indexElm = this.readyToDelete.findIndex(
        (key: any) => item.bagId === key.bagId
      );
      this.readyToDelete.splice(indexElm, 1);
    }
    this.$emit("update:readyToDelete", this.readyToDelete);
  }

  onSelectAll() {
    this.selectAllDebounce(this.data);
  }

  selectAllDebounce = debounce((data: any) => {
    if (this.isSelectAll) {
      if (this.filterCheckBox) {
        this.selectedCheckbox = data.filter((item: any) =>
          this.filterCheckBox(item)
        );
      } else {
        this.selectedCheckbox = data;
      }
    } else {
      this.selectedCheckbox = [];
    }
  }, 500);

  onClearCheckbox() {
    this.selectedCheckbox = [];
    this.isSelectAll = false;
    this.$emit("clearCheckbox", this.selectedCheckbox);
  }
  onDownloadResi() {
    this.$emit("downloadResi", this.selectedCheckbox);
  }

  onDownloadForm() {
    this.$emit("downloadForm", this.selectedCheckbox);
  }
  // download file
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    downloadFile(url, newFileName, ".csv");
  }

  // scroll bottom
  scrollPosition = 0;
  onScrollTableParent(e: any) {
    if (e.target.scrollLeft === this.scrollPosition) {
      return;
    }
    this.scrollPosition = e.target.scrollLeft;
  }

  // filtered checkbox
  filteredCheckbox(item: any) {
    if (this.filterCheckBox) {
      return this.filterCheckBox(item);
    }
    return true;
  }

  // quick search
  searchValue = "";
  onSearch(val: string) {
    this.toDirectPage(1);
    this.searchValue = val;
  }
  clearSearch() {
    this.toDirectPage(1);
    this.searchValue = "";
    this.requestNewData();
  }

  // sorting
  get sortingOptions() {
    return [
      {
        value: "desc",
        name: this.$t("Terbaru"),
      },
      {
        value: "asc",
        name: this.$t("Terlama"),
      },
    ];
  }
  selectedSorting = {
    value: "",
    name: "",
  };
  onSelectSorting(val: any) {
    if (val.value === this.selectedSorting.value) {
      this.selectedSorting = {
        value: "",
        name: "",
      };
      return;
    }
    this.selectedSorting = val;
  }

  get groupNames() {
    let groupNames = this.prepareEenderedList.map((list) => list.group);
    groupNames = [...new Set(groupNames)];
    groupNames = groupNames.filter((g) => g);

    return groupNames;
  }

  get renderedList() {
    const tempList = [...this.prepareEenderedList];

    // move group row to last array
    if (this.isGroupRow) {
      this.groupNames.forEach((group) => {
        tempList.forEach(() => {
          tempList.push(
            tempList.splice(
              tempList.findIndex((list) => {
                return list.group.toLowerCase() == group.toLowerCase();
              }),
              1
            )[0]
          );
        });
      });
    }
    return tempList;
  }

  get prepareEenderedList() {
    if (this.isHardPagination) {
      return this.filteredDataBySearch.slice(
        this.pagination.limit * (this.pagination.page - 1),
        this.pagination.limit +
          this.pagination.limit * (this.pagination.page - 1)
      );
    }
    if (
      this.hidePagination ||
      (this.isQuickSearch && this.searchValue.length)
    ) {
      return this.filteredDataBySearch;
    }
    return this.data;
  }
  get filteredCheckboxLength() {
    return this.data.filter((item: any) => this.filteredCheckbox(item)).length;
  }
  get filteredDataBySearch() {
    const dataFiltered = this.data.filter((item: any) => {
      for (const idx in item) {
        if (Object.prototype.hasOwnProperty.call(item, idx)) {
          const data = item[idx];
          if (
            JSON.stringify(data !== undefined ? data : "")?.includes(
              this.searchValue
            )
          ) {
            return true;
          }
        }
      }
    });
    if (this.selectedSorting.value === "desc") {
      return dataFiltered.reverse();
    }
    return dataFiltered;
  }
  get availablePage() {
    return (
      ((this.filteredDataBySearch.length / this.pagination.limit) >> 0) +
      (this.filteredDataBySearch.length % this.pagination.limit > 0 ? 1 : 0)
    );
  }
  get firstOfTable() {
    return 1 + this.pagination.limit * (this.pagination.page - 1);
  }
  get lastOfTable() {
    return (
      this.renderedList.length +
      this.pagination.limit * (this.pagination.page - 1)
    );
  }

  replaceWhiteSpaces = replaceWhiteSpaces;

  // able click on value
  columnNameActive = "";
  isIndexActive = 0;
  onHoverValue(event: any, column: any, item: any, index: number) {
    this.columnNameActive = column.name;
    this.isIndexActive = index;
    if (column.hideIconTooltip && item.reasonFailed.length > 50)
      this.toggleInfoPanel(event, column, index);
  }
  isDisableClickValue(column: any, item: any, index: number) {
    return (
      !!column.disableButtonValue && !!column.disableButtonValue(item, index)
    );
  }

  async initInfiniteScroll() {
    if (this.infiniteScroll && this.data.length) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (
              this.data.length <
              this.pagination.page * this.pagination.limit
            ) {
              return;
            }
            this.pagination.page++;
            this.requestNewData();
          }
        });
      }, {});

      const element: any = document.querySelector("#infinite-table-row");
      observer.observe(element);
    }
  }

  // hover on row
  hoverRowIndex = -1;
  onHoverRow(event: any, index: number) {
    if (!this.ableToHoverPerRow) return;
    const refs: any = this.$refs;
    refs[`hoverRow${index}`]?.show(event);
    this.hoverRowIndex = index;
  }
  onLeaveHoverRow(event: any, index: number) {
    if (!this.ableToHoverPerRow) return;
    const refs: any = this.$refs;
    refs[`hoverRow${index}`]?.hide();
    this.hoverRowIndex = -1;
  }

  onHoverCustomCheckbox(event: any, index: number, item: any) {
    if (!this.customModelCheckbox) return;
    const refs: any = this.$refs;
    refs[`hoverCheckbox${index}`]?.show(event);
    this.messageTooltipCargo(item);
    this.hoverRowIndex = index;
  }

  onLeaveHoverCustomCheckbox(_: any, index: number) {
    if (!this.customModelCheckbox) return;
    const refs: any = this.$refs;
    refs[`hoverCheckbox${index}`]?.hide();
    this.hoverRowIndex = -1;
  }

  messageTooltipCargo(item: any) {
    if (item.isCargo) return "Telah mendapatkan No. kargo";
    if (item.isRtcProcess) return "Dalam proses Siap Dikargo/RTC";
    if (item.isAlreadyRTC) return "Telah Siap Dikargo";
    else if (!item.isAbleRTC) return "Pilih untuk proses ke Siap Dikargo";
  }

  onHiglightData(indexArr: number) {
    return this.highlightData.includes(indexArr);
  }

  get loadingMainTable() {
    return this.infiniteScroll ? true : !this.loading;
  }

  onCheckCustomCheckbox(item: any) {
    if (item?.isCargo) {
      return "grey";
    } else if (item?.isRtcProcess) {
      return "yellow";
    } else if (item?.isAlreadyRTC) {
      return "red";
    }
    return "empty";
  }

  refs: any = {};
  onOpenTooltipPromo(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`infoPromo${index}`].toggle(event);
  }

  isHasGroupRow(group: string, index: number): boolean {
    const groupName = this.groupNames.find((g) => g === group);
    if (!groupName) return false;
    else {
      const idx = this.renderedList.findIndex(
        (list) => list.group === groupName
      );
      return idx === index;
    }
  }
}
